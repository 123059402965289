@import 'erpcore/assets/scss/main';

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font: $font-body;
    color: getColor(main-grey);
    background: getColor(white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
}

a {
    color: getColor(light-blue);
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: getColor(light-blue-hover);
        text-decoration: underline;
    }

    &:active {
        color: getColor(light-blue-hover);
        text-decoration: none;
    }

    &.link-with-icon {
        display: inline-flex;

        svg {
            margin: 0 $spacing;
            fill: getColor(light-blue);
        }

        &:hover {
            color: getColor(light-blue-hover);

            svg {
                fill: getColor(light-blue-hover);
            }
        }
    }
}

button * {
    pointer-events: none;
}

p,
ul,
ol {
    margin: 0 0 $component_margin;
}

p:last-child,
ul:last-child,
ol:last-child {
    margin-bottom: 0;
}

a,
a img,
button {
    transition: getTransition();
}

table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: $spacing;
}

// Images

figure {
    position: relative;
    margin: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

figure img {
    display: block;
    width: 100%;
    height: auto;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

svg:not(:root) {
    overflow: hidden;

    > * {
        pointer-events: none;
    }
}

// Global
.ordered-list {
    padding: 0;
    margin: 0 0 $spacing * 2;
    counter-reset: ordered-list-counter;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        position: relative;
        display: block;
        padding: 0 0 0 18px;
        margin: 0 0 2px;
        counter-increment: ordered-list-counter;

        &:before {
            display: block;
            content: counter(ordered-list-counter) '.';
            position: absolute;
            left: 0;
        }
    }
}

.red {
    color: getColor(red)
}

.green {
    color: getColor(apple)
}

.nowrap {
    white-space: nowrap;
}

// React grid
.react-draggable-dragging {
    pointer-events: none;
}

.react-grid-item {
    .widget {
        height: 100%;

        &__content {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &.react-grid-placeholder {
        background-color: getColor(silver-grey) !important;
        border-radius: 3px;
    }
}

.lelelel {
    position: relative;
}

.lelelel > div {
    position: absolute;
}