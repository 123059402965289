@import 'erpcore/assets/scss/main';

.DateRangePickerInput {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 3px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    background-color: rgba(getColor(white), 1);
    border: 2px solid rgba(getColor('light-grey'), 1);
    padding: 0 ($spacing * 6) 0 ($spacing * 2);

    &_arrow {
        opacity: 0;
        visibility: hidden;
        margin-right: $spacing;
        transition: getTransition();

        &_svg {
            width: $spacing * 2;
            height: $spacing * 2;
        }
    }
}

.DateInput {
    display: flex;
    width: 95px;
    height: $spacing * 4.5;
    align-items: center;
    background-color: transparent;

    &_input {
        padding: 0;
        width: 100%;
        border: none;
        display: block;
        font: $font-form;
        box-shadow: none;
        border-radius: 0;
        height: 24px;
        color: rgba(getColor(black), 1);
        -webkit-appearance: none;
        cursor: pointer;

        &__focused {
            border-bottom: 1px dotted var(--hover-color);
        }
    }

    .input.input--daterange.input--daterange-no-value:not(.input--focused) & {
        &:first-child {
            width: 100%;

            ~ .DateRangePickerInput_arrow {
                display: none;

                ~ .DateInput {
                    display: none;
                }
            }
        }
    }
}

.DayPicker {
    cursor: initial;

    @include breakpoint($to: tablet) {
        padding-top: 30px;
    }
}

.DayPicker_focusRegion {
    padding-bottom: $spacing * 2;
}

.DateRangePicker {
    &_picker {
        z-index: 999999999;

        &__portal {
            background-color: rgba(black, 0.4);
            cursor: pointer;
        }

        &__fullScreenPortal {
            background-color: rgba(black, 0.4);
        }
    }

    &_closeButton {
        &_svg {
            height: $spacing * 2;
            width: $spacing * 2;
            // fill: black;
        }
    }

    .input__action {
        top: 2px;
    }
}

.DateRangePickerInput__disabled {
    background-color: getColor(light-grey);
}

.CalendarMonth_table {
    margin-top: 20px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.CalendarDay__selected_span {
    background-color: var(--primary-color-lighter);
    border: 1px solid var(--primary-color-lighter);
}

.CalendarDay__default:hover {
    color: getColor(white);
    background-color: var(--hover-color);
    border: 1px solid var(--hover-color);
}

.CalendarDay__hovered_span {
    color: getColor(white);
    background-color: var(--primary-color-lighter);
    border: 1px solid var(--primary-color-lighter);
}

.input--daterange .input__action {
    top: 2px;
}

.daterange-presets {
    position: absolute;
    z-index: 2;
    top: $spacing * 2 + 2px;
    transform: translateX(-50%);
    left: 50%;

    @include breakpoint($to: tablet) {
        top: 12px;
    }

    &__select {
        max-width: 145px;
        border: none;
        padding: $spacing / 2 0;
        background: rgba(getColor(light-grey), 0.5);

        @include breakpoint($to: tablet) {
            max-width: 100%;
            font-size: 16px;
        }
    }
}

.input--error .DateRangePickerInput {
    border: 2px solid getColor(tomato);
}

.input--active .DateRangePickerInput_arrow {
    opacity: 1;
    visibility: visible;
}
