@import 'erpcore/assets/scss/main';

.form {
    $this: &;
    width: 100%;
    position: relative;
    margin: 0 0 ($spacing * 3) 0;

    &--auto-width {
        width: auto;
    }

    &:last-child {
        margin-bottom: 0px;
    }

    &__column {
        flex: 1;
        width: 100%;
        border: none;
        padding: 0px;
        margin: 0px;
    }

    &__section {
        &-title {
            font: $font-2;
            margin: ($spacing * 2) 0px ($spacing * 3) 0px;

            &:first-child {
                margin-top: 0px;
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }

        &-title-small {
            font: $font-3;
            margin: ($spacing * 2) 0 $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &-subtitle {
            display: block;
            width: 100%;
            font: $font-body;
            margin: $spacing 0 0 0;
        }
    }

    &__row {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 ($spacing * 2) 0;

        @include breakpoint($to: tablet) {
            display: block;
        }

        &--vertical-center {
            align-items: center;
        }

        &:last-child,
        &:empty {
            margin-bottom: 0px;
        }

        &-column {
            width: 50%;
            flex: 0 0 50%;
            padding-left: $spacing;
            padding-right: $spacing;

            @include breakpoint($to: tablet) {
                width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $spacing * 2;
            }

            &:first-child,
            &:empty {
                padding-left: 0;
            }

            &:last-child,
            &:empty {
                padding-right: 0;

                @include breakpoint($to: tablet) {
                    margin-bottom: 0;
                }
            }

            &-responsive {
                width: auto;
                display: block;

                @include breakpoint($to: tablet) {
                    margin-bottom: $spacing * 2;
                }

                &:last-child {
                    @include breakpoint($to: tablet) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__columns,
    &--with-columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 3;

        &:last-child {
            margin-bottom: 0px;
        }

        @include breakpoint($to: tablet) {
            flex-direction: column;
        }

        &--vertical-center {
            align-items: center;
        }

        #{$this}__column {
            flex-basis: 50%;
            width: 50%;
            margin-right: $spacing * 8;

            &:last-child {
                margin-right: 0px;

                @include breakpoint($to: tablet) {
                    margin-bottom: 0px;
                }
            }

            @include breakpoint($to: tablet) {
                width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: $spacing * 2;
                margin-right: 0px;
            }
        }
    }

    &__help-text {
        text-align: right;

        @include breakpoint($to: tablet) {
            text-align: left;
        }
    }

    &--inline {
        display: inline-block;
        width: auto;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-right: $spacing;
        }
    }

    &__row--start {
        justify-content: flex-start;
    }

    &__group {
        $_top_offset: 12px;
        position: relative;
        flex: 1 1;
        width: 50%;
        margin: 0 $spacing * 2 $spacing * 2 0;

        @include breakpoint($to: desktop) {
            flex-basis: 100%;
        }

        &--with-label {
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                top: -$_top_offset;
                bottom: 0;
                left: 0;
                right: 0;
                border: 2px solid getColor('light-grey');
                clip-path: polygon(
                    0px 0px,
                    100% 0%,
                    100% #{$_top_offset - 4px},
                    0 #{$_top_offset - 4px}
                );
            }

            @include breakpoint($to: desktop) {
                margin-top: $_top_offset * 2;
            }
        }

        &:last-child {
            margin-right: 0;

            @include breakpoint($to: tablet) {
                margin-bottom: 0;
            }
        }

        @include breakpoint($to: tablet) {
            width: 100%;
            // margin-bottom: $spacing * 2;
        }

        &-label {
            position: absolute;
            top: -#{10px + $_top_offset};
            left: 50%;
            padding: 5px;
            max-width: calc(100% - 20px);
            font-size: 11px;
            line-height: 1;
            color: getColor(silver-grey);
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transform: translateX(-50%);
            z-index: 2;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
            );
            // pointer-events: none;
        }

        &-content {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            margin: 0;

            @include breakpoint($to: tablet) {
                // display: block;
            }

            .input {
                width: 50%;
                margin-bottom: 0;
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__grid-column {
        width: 50%;
        flex: 0 0 50%;
        margin-right: $spacing * 2;

        &--1 {
            flex-basis: calc(8.333333% - #{$spacing * 2} + (#{$spacing * 2} / 12));
            max-width: calc(8.333333% - #{$spacing * 2} + (#{$spacing * 2} / 12));

            &:nth-of-type(12n) {
                margin-right: 0;
            }
        }

        &--2 {
            flex-basis: calc(16.666667% - #{$spacing * 2} + (#{$spacing * 2} / 6));
            max-width: calc(16.666667% - #{$spacing * 2} + (#{$spacing * 2} / 6));

            &:nth-of-type(6n) {
                margin-right: 0;
            }
        }

        &--3 {
            flex-basis: calc(25% - #{$spacing * 2} + (#{$spacing * 2} / 4));
            max-width: calc(25% - #{$spacing * 2} + (#{$spacing * 2} / 4));

            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }

        &--4 {
            flex-basis: calc(33.333333% - #{$spacing * 2} + (#{$spacing * 2} / 3));
            max-width: calc(33.333333% - #{$spacing * 2} + (#{$spacing * 2} / 3));

            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }

        &--5 {
            flex-basis: 41.666667%;
            max-width: 41.666667%;
        }

        &--6 {
            flex-basis: calc(50% - #{$spacing * 2} + (#{$spacing * 2} / 2));
            max-width: calc(50% - #{$spacing * 2} + (#{$spacing * 2} / 2));

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        &--7 {
            flex-basis: 58.333333%;
            max-width: 58.333333%;
        }

        &--8 {
            flex-basis: 66.666667%;
            max-width: 66.666667%;
        }

        &--9 {
            flex-basis: 75%;
            max-width: 75%;
        }

        &--10 {
            flex-basis: 83.333333%;
            max-width: 83.333333%;
        }

        &--11 {
            flex-basis: 91.666667%;
            max-width: 91.666667%;
        }

        &--12 {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @include breakpoint($to: tablet) {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: $spacing * 2;
        }
    }

    &--filters {
        .react-select__control {
            font: $font-filters;

            @include breakpoint($to: tablet) {
                font: $font-form;
            }
        }

        .input__label {
            font: $font-filters;
            line-height: 1;
            transform: translate(15px, 9px);

            @include breakpoint($to: tablet) {
                transform: translate(15px, 7px);
                font: $font-form;
                line-height: 1;
            }

            &--active {
                font-size: 11px;
                transform: translate(15px, -11px);

                @include breakpoint($to: tablet) {
                    transform: translate(15px, -11px);
                }
            }
        }

        .react-select__single-value {
            padding: $spacing * 1.2 $spacing * 5 $spacing 0;

            @include breakpoint(tablet) {
                padding: $spacing 46px $spacing 0;
            }
        }

        .react-select__option {
            font: $font-filters;

            @include breakpoint($to: tablet) {
                font: $font-form;
            }
        }

        .DateInput_input {
            font: $font-filters;
            text-align: center;

            @include breakpoint($to: tablet) {
                font: $font-form;
                text-align: left;
            }
        }

        .DateRangePickerInput_arrow {
            margin-right: 0;

            @include breakpoint($to: tablet) {
                margin-right: $spacing;
            }
        }

        .DateRangePickerInput {
            padding: 0 $spacing * 6 0 $spacing;

            @include breakpoint($to: tablet) {
                padding: 0 $spacing * 6 0 $spacing * 2;
            }
        }
    }
}

// View Pages
.view-only {
    .input {
        &__label {
            left: 0px;
            transform: translate(0px, -11px);
            padding-left: 0px;
        }

        input,
        &__field {
            background-color: transparent;
            border: none;
            padding: $spacing 0px;
            height: auto;
            min-height: auto;
            font: $font-form;
        }

        &--disabled {
            opacity: 1;
            min-height: $spacing * 5;
        }
    }

    .react-select--is-disabled {
        .react-select__control {
            background-color: transparent;
            border: none;
            padding: 0px;
        }

        .react-select__indicators {
            display: none;
        }
    }

    .react-phone-number-input {
        &.input__field {
            padding: 0px;
        }

        &__country--native {
            display: none;
        }
    }
}
