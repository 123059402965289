@import 'erpcore/assets/scss/main';

$datepicker__background-color: getColor('white') !default;
$datepicker__border-color: white !default;
$datepicker__highlighted-color: getColor('light-grey') !default;
$datepicker__muted-color: getColor('light-grey') !default;
$datepicker__text-color: getColor('dark-grey') !default;
$datepicker__header-color: getColor('dark-grey') !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 50% !default;
$datepicker__day-margin: 2px !default;
$datepicker__font-size: 13px;
$datepicker__item-size: $spacing * 4 !default;
$datepicker__margin: $spacing !default;
$datepicker__navigation-size: $spacing !default;
$datepicker__triangle-size: $spacing * 2 !default;

%triangle-arrow {
    z-index: 9999;
    margin-left: -$datepicker__triangle-size;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: $datepicker__triangle-size solid transparent;

        height: 0;
        width: 1px;
    }

    &::before {
        content: '';
        z-index: -1;
        border-width: $datepicker__triangle-size;

        left: -$datepicker__triangle-size;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$datepicker__triangle-size;

    &,
    &::before {
        border-top: none;
        border-bottom-color: $datepicker__background-color;
    }

    &::before {
        top: -1px;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$datepicker__triangle-size;

    &,
    &::before {
        border-bottom: none;
        border-top-color: #fff;
    }

    &::before {
        bottom: -1px;
        border-top-color: $datepicker__border-color;
    }
}

%triangle-arrow {
    margin-left: -$datepicker__triangle-size;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: $datepicker__triangle-size solid transparent;

        height: 0;
        width: 1px;
    }

    &::before {
        content: '';
        z-index: -1;
        border-width: $datepicker__triangle-size;

        left: -$datepicker__triangle-size;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$datepicker__triangle-size;

    &,
    &::before {
        border-top: none;
        border-bottom-color: $datepicker__background-color;
    }

    &::before {
        top: -1px;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$datepicker__triangle-size;

    &,
    &::before {
        border-bottom: none;
        border-top-color: #fff;
    }

    &::before {
        bottom: -1px;
        border-top-color: $datepicker__border-color;
    }
}

.react-datepicker-wrapper {
    display: inline-block;
    width: 100%;

    .input__field {
        cursor: pointer !important;
        background: #fff;
        text-align: left;

        &:focus,
        &:focus-visible,
        &:focus-within {
            border-color: getColor(mid-grey);
        }

        .input--disabled & {
            cursor: default !important;
            background-color: getColor(light-grey);
        }
    }
}

.react-datepicker {
    font-size: $datepicker__font-size;
    background-color: #fff;
    color: $datepicker__text-color;
    border: 1px solid $datepicker__border-color;
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    &__month-dropdown-container--select,
    &__year-dropdown-container--select {
        position: relative;

        &:after {
            content: ' ';
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 13px;
            right: 6px;
            background-image: url(~erpcore/assets/svg/arrowDown.svg);
            background-size: 8px 8px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }

        select {
            position: relative;
            display: block;
            width: 100%;
            max-width: 100%;
            height: $spacing * 5;
            margin: 0;
            padding: 0 ($spacing * 3) 0 ($spacing * 2);
            font: $font-form;
            border-radius: 3px;
            font-weight: normal;
            resize: none;
            color: getColor(black);
            background-color: getColor('white');
            border: 2px solid getColor('light-grey');
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            &::-ms-expand {
                display: none;
            }

            &:hover {
                cursor: pointer;
            }

            &:focus {
            }

            option {
                font-weight: 400;
            }
        }
    }
}

.react-datepicker--time-only {
    padding-left: 0;

    .react-datepicker__triangle {
        left: 35px;
    }

    .react-datepicker__time-container {
        border-left: 0;
    }

    .react-datepicker__time {
        border-radius: 0.3rem;
    }

    .react-datepicker__time-box {
        border-radius: 0.3rem;
    }
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 10000;
    background: getColor(white);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);

    &[data-placement^='bottom'] {
        margin-top: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend %triangle-arrow-up;
        }
    }

    &[data-placement^='top'] {
        margin-bottom: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend %triangle-arrow-down;
        }
    }

    &[data-placement^='right'] {
        margin-left: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }
    }

    &[data-placement^='left'] {
        margin-right: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }
    }
}

.react-datepicker__header {
    text-align: center;
    background-color: $datepicker__background-color;
    border-bottom: 1px solid $datepicker__border-color;
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
    position: relative;

    &--time {
    }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    margin-bottom: $spacing;
    color: $datepicker__header-color;
    font: $font-3;
    font-size: $datepicker__font-size * 1.18;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-indent: -15px;
}

.react-datepicker__navigation {
    background: none;
    line-height: $datepicker__item-size;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: $spacing * 3;
    width: 0;
    padding: 0;
    border: $datepicker__navigation-size solid transparent;
    z-index: 1;
    text-indent: -999em;

    &--previous {
        left: $spacing * 5 - 4;

        &:hover {
        }

        &:after {
            transform: rotate(-45deg);
            left: 4px;
        }

        &--disabled,
        &--disabled:hover {
            cursor: default;
            opacity: 0.2;
        }
    }

    &--next {
        right: $spacing * 5 - 4;

        &:after {
            transform: rotate(135deg);
            left: 2px;
        }

        &--with-time:not(&--with-today-button) {
            right: $spacing * 17 - 4;
        }

        &:hover {
        }

        &--disabled,
        &--disabled:hover {
            cursor: default;
            opacity: 0.2;
        }
    }

    &--previous,
    &--next {
        width: $spacing * 4;
        height: $spacing * 4;
        background-color: getColor('light-grey');
        border-radius: 50%;

        &:after {
            position: absolute;
            content: '';
            display: block;
            top: 3px;
            width: 7px;
            height: 7px;
            border-left: 2px solid getColor(black);
            border-top: 2px solid getColor(black);
        }
    }

    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-previous {
            top: 4px;
            border-top-color: $datepicker__muted-color;

            &:hover {
                border-top-color: darken($datepicker__muted-color, 10%);
            }
        }

        &-upcoming {
            top: -4px;
            border-bottom-color: $datepicker__muted-color;

            &:hover {
                border-bottom-color: darken($datepicker__muted-color, 10%);
            }
        }
    }
}

.react-datepicker__month-container {
    margin: $spacing * 4;
}

.react-datepicker__month {
    margin-top: 0;
    text-align: center;

    .react-datepicker__month-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    text-align: left;

    .react-datepicker-time__caption {
        display: inline-block;
    }
    .react-datepicker-time__input-container {
        display: inline-block;
        .react-datepicker-time__input {
            display: inline-block;
            margin-left: 10px;
            input {
                width: 85px;
            }
            input[type='time']::-webkit-inner-spin-button,
            input[type='time']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type='time'] {
                -moz-appearance: textfield;
            }
        }
        .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }
    }
}

.react-datepicker__time-container {
    padding: $spacing * 4 0;
    border-left: 1px solid $datepicker__border-color;
    width: 100px;

    &--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }

    .react-datepicker__time {
        position: relative;
        background: white;

        .react-datepicker__time-box {
            width: 100px;
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
            ul.react-datepicker__time-list {
                list-style: none;
                margin: 0;
                height: calc(195px + (#{$datepicker__item-size} / 2));
                overflow-y: scroll;
                padding-right: 0px;
                padding-left: 0px;
                width: 100%;
                box-sizing: content-box;

                &::-webkit-scrollbar {
                    width: 15px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: getColor('silver-grey');
                    border: 3px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                }

                li.react-datepicker__time-list-item {
                    height: 30px;
                    line-height: 32px;
                    border-radius: 3px;
                    margin: 6px 0;

                    &:hover {
                        cursor: pointer;
                        background-color: getColor('light-grey');
                    }

                    &--selected {
                        background-color: var(--primary-color);
                        color: white;
                        font-weight: bold;
                        &:hover {
                            background-color: var(--primary-color);
                        }
                    }
                    &--disabled {
                        color: $datepicker__muted-color;

                        &:hover {
                            cursor: default;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

.react-datepicker__week-number {
    color: $datepicker__muted-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;
    &.react-datepicker__week-number--clickable {
        cursor: pointer;
        &:hover {
            border-radius: $datepicker__border-radius;
            background-color: $datepicker__background-color;
        }
    }
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;

    .react-datepicker__day-name {
        color: getColor('silver-grey');
        font-weight: bold;
    }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $datepicker__text-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;
    cursor: pointer;
}

.react-datepicker__day {
    &:hover {
        background: getColor('light-grey');
    }
}

.react-datepicker__month {
    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $datepicker__border-radius;
        background-color: var(--primary-color);
        color: #fff;

        &:hover {
            background-color: var(--hover-color);
        }
    }
    &--disabled {
        color: $datepicker__muted-color;
        pointer-events: none;
        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}

.react-datepicker__day,
.react-datepicker__month-text {
    cursor: pointer;

    &:hover {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__background-color;
    }

    &--today {
        font-weight: bold;
    }

    &--highlighted {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__highlighted-color;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__highlighted-color, 5%);
        }

        &-custom-1 {
            color: magenta;
        }

        &-custom-2 {
            color: green;
        }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $datepicker__border-radius;
        background-color: var(--primary-color);
        color: #fff;

        &:hover {
            background-color: var(--hover-color);
        }
    }

    //    &--keyboard-selected {
    //        border-radius: $datepicker__border-radius;
    //        background-color: var(--primary-color);
    //        color: #fff;
    //
    //        &:hover {
    //            background-color: var(--hover-color);
    //        }
    //    }

    &--in-selecting-range:not(&--in-range) {
        background-color: var(--primary-color);
    }

    &--in-range:not(&--in-selecting-range) {
        .react-datepicker__month--selecting-range & {
            background-color: $datepicker__background-color;
            color: $datepicker__text-color;
        }
    }

    &--disabled {
        cursor: default;
        color: $datepicker__muted-color;

        &:hover {
            background-color: transparent;
        }
    }
}

.react-datepicker__month-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range {
        &:hover {
            background-color: var(--primary-color);
        }
    }
    &:hover {
        background-color: $datepicker__background-color;
    }
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: $datepicker__border-radius;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: darken($datepicker__muted-color, 10%);
        }
    }

    &--down-arrow {
        @extend %triangle-arrow-down;
        border-top-color: $datepicker__muted-color;
        float: right;
        margin-left: 20px;
        top: 8px;
        position: relative;
        border-width: $datepicker__navigation-size;
    }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: $datepicker__background-color;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: $datepicker__border-radius;
    border: 1px solid $datepicker__border-color;

    &:hover {
        cursor: pointer;
    }

    &--scrollable {
        height: 150px;
        overflow-y: scroll;
    }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top-left-radius: $datepicker__border-radius;
        border-top-right-radius: $datepicker__border-radius;
    }

    &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: $datepicker__border-radius;
        border-bottom-right-radius: $datepicker__border-radius;
    }

    &:hover {
        background-color: $datepicker__muted-color;

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: darken($datepicker__muted-color, 10%);
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: darken($datepicker__muted-color, 10%);
        }
    }

    &--selected {
        position: absolute;
        left: 15px;
    }
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;

    &::after {
        background-color: var(--primary-color);
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: '\00d7';
        cursor: pointer;
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
    }
}

.react-datepicker__today-button {
    background: $datepicker__background-color;
    border-top: 1px solid $datepicker__border-color;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    // Resize for small screens
    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: $datepicker__font-size * 1.8;
    }

    .react-datepicker__navigation {
        border: 1.8 * $datepicker__navigation-size solid transparent;
    }

    .react-datepicker__navigation--previous {
        border-right-color: $datepicker__muted-color;

        &:hover {
            border-right-color: darken($datepicker__muted-color, 10%);
        }

        &--disabled,
        &--disabled:hover {
            border-right-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }

    .react-datepicker__navigation--next {
        border-left-color: $datepicker__muted-color;

        &:hover {
            border-left-color: darken($datepicker__muted-color, 10%);
        }

        &--disabled,
        &--disabled:hover {
            border-left-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }
}

.react-datepicker__header__dropdown {
    margin-top: $spacing * 2;
    margin-bottom: $spacing;
}

.react-datepicker__month-dropdown-container {
    margin-right: $spacing * 2;

    &:last-child {
        margin-right: 0;
    }
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    border: none;
}
