$colors: (
    bordo: #831b45,
    bordo-hover: #5c092b,
    dark-grey: #323232,
    dark-grey-hover: rgba(#323232, 0.8),
    light-blue: #1700b8,
    light-blue-hover: #100081,
    red: #da3d39,
    red-hover: #aa302c,
    black: #000000,
    main-grey: #191919,
    mid-grey: #646464,
    silver-grey: #b3b3b3,
    silver-light-grey: #dedede,
    light-grey: #f2f2f2,
    white: #ffffff,
    sun: #f0c356,
    tomato: #ed767a,
    sky: #56a8c2,
    apple: #8dc875,
    lavander: #bf87e1,
    neon: #7474ee,
    orange: #f88d4c,
    bordoblack: linear-gradient(to bottom, #831b45, #1a071e),
    hero-bordoblack: linear-gradient(315deg, #831b45, #1a071e)
);
